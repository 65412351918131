<template>
  <v-row>
    <v-col cols="12" sm="6" lg="3">
      <base-material-card
        inline
        class="mb-0 fill-height"
        icon="mdi-account-supervisor"
        :title="$t('Academics') + ' ' + academics"
      >
      </base-material-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Academics didactic rank/year") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartData" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Academics employment contract/year") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartDataContract" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Academics phd supervisort/year") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-line :styles="myStyles" :chart-data="chartDataPhd" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Academics accounts") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartDataIds" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Publons counts") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartDataPublons" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Scopus counts") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-bar :styles="myStyles" :chart-data="chartDataScopus" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="my-0">
        <v-card-title>
          {{ $t("Scientometrics - Journals width IF") }}
        </v-card-title>
        <v-card-text>
          <v-sheet height="200px">
            <chart-line :styles="myStyles" :chart-data="chartDataIF" :options="options" />
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "../../../plugins/axios"
import ChartBar from "../charts/Bar"
import ChartLine from "../charts/Line"
export default {
  name: "DashboardDashboard",
  components: { ChartBar, ChartLine },
  data() {
    return {
      academics: 0,
      chartData: {},
      chartDataIF: {},
      chartDataContract: {},
      chartDataPhd: {},
      chartDataIds: {},
      chartDataPublons: {},
      chartDataScopus: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }
          ]
        }
      },
      colors: [
        "#42A5F5",
        "#66BB6A",
        "#EF5350",
        "#FFA726",
        "#7E57C2",
        "#26A69A",
        "#5C6BC0",
        "#9CCC65",
        "#1565C0",
        "#C62828",
        "#9E9D24",
        "#00695C",
        "#2E7D32",
        "#00838F",
        "#558B2F",
        "#0277BD",
        "#283593",
        "#4527A0",
        "#283593",
        "#EF6C00",
        "#37474F",
        "#4E342E"
      ]
    }
  },
  computed: {
    myStyles() {
      return {
        height: "200px",
        position: "relative"
      }
    }
  },
  created() {
    this.getRecords()
  },
  methods: {
    getRecords() {
      axios.get("infuni_academics/?max_results=1&page=1").then(response => {
        //this.$log("products list ", response)
        this.academics = response.data._meta.total
      })
      axios.get("academics_chart_year_rank?max_results=1000").then(response => {
        //this.$log("academics_chart_year_rank ", response)
        this.chartData = this.getDataChart(response.data._items)
      })
      axios.get("academics_chart_year_contract?max_results=1000").then(response => {
        //this.$log("academics_chart_year_contract ", response)
        this.chartDataContract = this.getDataChart(response.data._items)
      })
      axios.get("academics_chart_year_phd?max_results=1000").then(response => {
        //this.$log("academics_chart_year_phd ", response)
        this.chartDataPhd = this.getDataChart(response.data._items)
      })
      axios.get("academics_chart_ids?max_results=1000").then(response => {
        this.$log("academics_chart_ids ", response)
        //this.chartDataPhd = this.getDataChart(response.data._items)
        let colorid = 0
        this.chartDataIds = {
          labels: ["Accounts"],
          datasets: [
            {
              label: "Scopus",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].scopus]
            },
            {
              label: "Publons",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].publons]
            },
            {
              label: "ORCiD",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].prcid]
            }
          ]
        }
      })
      axios.get("publons_chart_counts").then(response => {
        this.$log("publons_chart_counts ", response)
        let colorid = 0
        this.chartDataPublons = {
          labels: ["Counts"],
          datasets: [
            {
              label: "Publications",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].publications]
            },
            {
              label: "Reviews",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].reviews]
            },
            {
              label: "Reviews post",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].reviewspost]
            },
            {
              label: "Editorials",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].editorials]
            }
          ]
        }
      })
      axios.get("scopus_chart_counts").then(response => {
        this.$log("scopus_chart_counts ", response)
        let colorid = 0
        this.chartDataScopus = {
          labels: ["Counts"],
          datasets: [
            {
              label: "Publications",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].publications]
            },
            {
              label: "Citations",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].citations]
            },
            {
              label: "Cited by",
              borderColor: this.colors[colorid],
              backgroundColor: this.colors[colorid++],
              fill: false,
              borderWidth: 2,
              barThickness: 10,
              data: [response.data._items[0].citedby]
            }
          ]
        }
      })
      axios.get("scientometrics_chart_year_if").then(response => {
        this.$log("scientometrics_chart_year_if ", response)
        this.chartDataIF = this.getDataChart(response.data._items)
      })
    },
    getDataChart(data) {
      const recs = {}
      const years = []
      const types = []
      data.map(itm => {
        if (years.indexOf(itm.year) < 0) {
          years.push(itm.year)
        }
        if (types.indexOf(itm.type) < 0) {
          types.push(itm.type)
        }
        if (!recs[itm.year]) {
          recs[itm.year] = {}
        }
        recs[itm.year][itm.type] = itm.value
      })
      years.sort()
      types.sort()
      const datas = []
      const dt = {}
      years.map(year => {
        types.map(type => {
          if (!dt[type]) {
            dt[type] = []
          }
          dt[type].push(recs[year][type])
        })
      })
      let colorid = 0
      types.map(type => {
        datas.push({
          label: type,
          borderColor: this.colors[colorid],
          backgroundColor: this.colors[colorid++],
          fill: false,
          borderWidth: 2,
          barThickness: 10,
          data: dt[type]
        })
      })
      return { labels: years, datasets: datas }
    }
  }
}
</script>
<style scoped>
td:first-child,
th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td {
  text-align: right !important;
}
td,
th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
th {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
